import React from 'react';
import { Form, Select } from 'antd';
import { baseValidationRules } from 'common/const/questionnaire.const';

const { Option } = Select;

interface IComponentProps {
  label?: string;
  options: string[];
  name: string;
  answers: string[];
  placeholder: string;
}

export const FormSelectGroup: React.FC<IComponentProps> = (props) => {
  const { label, options, name, answers, placeholder } = props;
  return (
    <>
      <span className="form__select__group__header">{label}</span>
      {answers.map((answer: string) => (
        <Form.Item
          className="form__select__group__item"
          key={answer}
          label={answer}
          // Name equals general id of group, answer - corresponding field name
          name={[name, answer]}
          rules={baseValidationRules()}
        >
          <Select placeholder={placeholder}>
            {options?.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      ))}
    </>
  );
};
