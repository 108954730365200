import React from 'react';
import { Checkbox, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { baseValidationRules } from 'common/const/questionnaire.const';
import { IQuestionnaireAnswer } from 'common/models/formBuilder.models';

interface IComponentProps {
  name: string;
  label?: string;
  checkboxButtons: string[];
  className?: string;
  checkboxValue?: string[];
  max?: number;
  answerObj?: IQuestionnaireAnswer;
  rules?: Rule[];
  required?: boolean;
}

export const FormCheckbox: React.FC<IComponentProps> = (props) => {
  const { name, label, checkboxButtons, className, max, answerObj, checkboxValue, rules, required = true } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={required ? rules || baseValidationRules() : undefined}
      initialValue={checkboxValue}
      required={required}
    >
      <Checkbox.Group className={className}>
        {checkboxButtons.map((item) => {
          return (
            <Checkbox
              key={item}
              value={item}
              disabled={max ? answerObj?.answers.length >= max && !answerObj?.answers.includes(item) : false}
            >
              {item}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
};
