import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { getQuestionnare } from 'common/helpers/switcher.helper';
import { IPatientAnswer, ISelectGroupAnswer, IBaseParams, IPathParam } from 'common/models/formBuilder.models';
import { useFormMapper } from 'common/helpers/antd/formMapper';
import { STATUS_BAR_HIDE_DELAY } from 'common/config';
import { answerIsObject } from 'common/utils/answerIsObject';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';
import {
  IQuestionnaireElement,
  IQuestionnaireModel,
  IQuestionnaireComment,
} from 'entities/PatientSessions/PatientSession.models';

type AllProps = IPatientSessionsConnectedProps & RouteComponentProps<IPathParam>;

const SwitcherComponent: React.FC<AllProps> = (props) => {
  const {
    match,
    getPatientSessionsModel,
    setPatientSessionsModel,
    patientSessionsModel,
    updatePatientSessionsModel,
    getAnswersCopyPatientSessionsModel,
    getPrevPatientSessionsModel,
  } = props;
  const patientSessionId = match.params.id;
  const { data, loading, errors } = patientSessionsModel;
  const [questionnaireId, setQuestionnaireId] = useState<string>('');
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaireModel>();
  const [patientAnswers, setPatientAnswers] = useState<IPatientAnswer[]>([]);
  const { fields } = useFormMapper(['elements'], data);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    getPatientSessionsModel(patientSessionId);
  }, []);

  useEffect(() => {
    if (data) {
      setQuestionnaireId(data.textId);
      setQuestionnaire(data);
    }
  }, [data]);

  useEffect(() => {
    if (fields) {
      const answers: IPatientAnswer[] = [];

      fields[0]?.value?.forEach((field: IQuestionnaireElement) => {
        if (field.patientAnswers !== undefined) {
          //   Handle answers for SelectGroup component
          if (field.patientAnswers instanceof Array && answerIsObject(field.patientAnswers[0])) {
            field.patientAnswers.forEach((answer: ISelectGroupAnswer) => {
              answers.push({ name: [field.textId, ...Object.keys(answer)], value: Object.values(answer).join() });
            });
          } else {
            answers.push({
              name: field.textId,
              value: field.type === 'datePicker' ? moment(field.patientAnswers) : field.patientAnswers,
            });
          }
        }
      });

      setPatientAnswers(answers);
    }
  }, [fields]);

  useEffect(() => {
    if (!loading && !errors) {
      setSuccess(true);

      const timeout = setTimeout(() => setSuccess(false), STATUS_BAR_HIDE_DELAY);

      return () => clearTimeout(timeout);
    }

    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }, [loading]);

  const filterEmptyComments = (comments: IQuestionnaireComment[]) => {
    return comments?.filter((comment: IQuestionnaireComment) => comment.comment.length) || [];
  };

  const submitData = (params: IBaseParams, update?: boolean) => {
    const comments: IQuestionnaireComment[] | [] = params?.comments ? filterEmptyComments(params.comments) : [];
    const data = {
      patientSessionId,
      questionnaireId,
      elements: params.elements,
      comments,
    };

    if (questionnaireId) {
      update ? updatePatientSessionsModel(data) : setPatientSessionsModel(data);
    }
  };

  return (
    <Layout className="layout" key={questionnaireId}>
      <Spin spinning={loading || !data?._id}>
        {questionnaire &&
          getQuestionnare(
            questionnaireId,
            questionnaire,
            submitData,
            setPatientAnswers,
            getAnswersCopyPatientSessionsModel,
            { loading: loading, success: success, errors: errors || false },
            patientAnswers,
            patientSessionId,
            getPrevPatientSessionsModel
          )}
      </Spin>
    </Layout>
  );
};

export const Switcher = withRouter(communicationPatientSessions.injector(SwitcherComponent));
