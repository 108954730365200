import { Button, Input } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as AddIcon } from 'app/assets/images/svg/add-btn.svg';

interface IComponentProps {
  name: string;
  question: string;
  onChange: (question: string, value: string) => void;
  mobile?: boolean;
}

export const AddOptionBtn: React.FC<IComponentProps> = (props) => {
  const { name, question, onChange, mobile } = props;
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const toggle = () => {
    setIsClicked(!isClicked);
    setValue('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    onChange(question, value);
    setIsClicked(!isClicked);
    setValue('');
  };

  return (
    <div className={mobile ? 'layout-mobile__add_container' : ''}>
      {!isClicked ? (
        <Button className={mobile ? 'form__btn layout-mobile__btn' : 'form__btn form__add_btn'} onClick={toggle}>
          <AddIcon className="mr-4" />
          <span className={mobile ? 'layout-mobile__btn_add' : ''}>{name}</span>
        </Button>
      ) : (
        <div>
          <Input onChange={handleChange} value={value} />
          <div className={mobile ? 'layout-mobile__btns' : 'form__btns form__add_btns'}>
            <Button className="form__btn" onClick={toggle}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleClick} disabled={!value.length}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
