import { useState, useEffect } from 'react';
import { FieldData } from 'rc-field-form/es/interface';

type TFormMapper = (
  fieldsNames: string[],
  data: Object | null
) => { fields: FieldData[]; setField: (newField: FieldData) => void };

const mapToFields = <T extends { [key: string]: any }>(stateData: FieldData[], data?: T | null): FieldData[] => {
  return stateData?.map(({ name, value }) => {
    const flatName: string | number = typeof name === 'string' || typeof name === 'number' ? name : name.join('.');

    const hasValue = data && typeof data === 'object' && flatName in data;
    const dataValue = data && hasValue ? data[flatName] : value;

    return { value: dataValue, name };
  });
};

export const useFormMapper: TFormMapper = (fieldsNames, data) => {
  const [fields, setFields] = useState<FieldData[]>(fieldsNames.map((item) => ({ name: item, value: undefined })));

  useEffect(() => {
    setFields((prevState) => mapToFields(prevState, data));
  }, [data, setFields]);

  const setField = (newField: FieldData) => {
    setFields((prevState) => {
      const stateWithoutNew = prevState.filter(({ name }: any) => name !== newField.name);
      return stateWithoutNew.concat([newField]);
    });
  };
  return { fields, setField };
};
